<template>
        <div class="col-sm-6 col-md-6 col-lg-6">
          <div class="card h-100">
            <div class="card-title small-card-title">
              العميل
            </div>
            <div class="card-body-1">

              <div class="row">

                <div class="col-6 building-line">
                  <div class="building-info">
                    <span class="building-title">الاسم الكامل</span>
                    <span class="building-value">{{ client?.name }}</span>
                  </div>
                </div>

                <div class="col-6">
                  <div class="building-info">
                    <span class="building-title">هاتف المنزل</span>
                    <span class="building-value" v-text="client?.phone ? client.phone : 'لا يوجد'"></span>
                  </div>
                </div>

                <div class="col-6 building-line">
                  <div class="building-info">
                    <span class="building-title">الجنسية</span>
                    <span class="building-value" v-text="client?.nationality ? client.nationality : 'لا يوجد'"></span>
                  </div>
                </div>

                <div class="col-6">
                  <div class="building-info">
                    <span class="building-title">هاتف العمل</span>
                    <span class="building-value" v-text="client?.work_phone ? client.work_phone : 'لا يوجد'"></span>
                  </div>
                </div>

                <div class="col-6 building-line">
                  <div class="building-info">
                    <span class="building-title">رقم الهوية</span>
                    <span class="building-value" v-text="client?.id_number ? client.id_number : 'لا يوجد'"></span>
                  </div>
                </div>

                <div class="col-6">
                  <div class="building-info">
                    <span class="building-title">رقم الموبايل</span>
                    <span class="building-value" v-text="client?.id_number ? client?.id_number : 'لا يوجد'"></span>
                  </div>
                </div>




              </div>

            </div>
          </div>
        </div>
</template>


<script>
export default {
  props: ['client'],
}
</script>