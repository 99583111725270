<template>
    <div>

        <div class="print-invoice" v-if="data">

            <div class="card">
                <div class="invoice-body">
                    <div class="row">
                        <div class="col-9">
                            <div class="row center-invoice-nav">
                                <div class="col-4">
                                    <div class="logo-side">
                                        <img :src="data.complex.logo" class="logo-invoice">
                                        <br>
                                        <span>{{ data.complex.name }}</span>
                                    </div>
                                </div>
                                <div class="col-4 text-center">
                                    <h4 class="invoince-title">وصل قبض</h4>
                                    <h4 class="invoince-title">RECEIPT VOUCHER</h4>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-6 text-left">رقم الوصل</div>
                                        <div class="col-6 text-right">Receipt No</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 number-box-container">
                                            <div class="number-box" >{{ data.id }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row section-invoice-2">
                                <div class="col-6">
                                    <div class="sales-center">
                                        <span class="sales-center">مركز المبيعات</span>
                                        <span class="sales-center">SALES CENTER</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="invoice-money">
                                        <div class="total-amount">
                                            <div class="cont-title">
                                                <div class="total-amount-title">المبلغ الكلي</div>
                                            </div>
                                            <div class="cont-value">
                                                <div class="total-amount-value">{{ data.sale.total_amount | currency }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="total-amount">
                                            <div class="cont-title">
                                                <div class="total-amount-title">المبلغ المتبقي</div>
                                            </div>
                                            <div class="cont-value">
                                                <div class="total-amount-value green-value">
                                                    {{ data.sale.remaining_amount | currency }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 p-t-1">
                                    <div class="invoice-body-content">
                                        <div class="invoice-body-line">
                                            <div class="invoice-body-title">استلمنا من</div>
                                            <div class="invoice-body-value">السييد \ {{ data.client?.name }}
                                            </div>
                                            <div class="invoice-body-title">Recived from</div>
                                        </div>
                                        <div class="invoice-body-line">
                                            <div class="invoice-body-title">مبلغ وقدره</div>
                                            <div class="invoice-body-value">{{ data.payment.amount }}</div>
                                            <div class="invoice-body-title">The amount of</div>
                                        </div>
                                        <div class="invoice-body-line">
                                            <div class="invoice-body-title">وذلك عن</div>
                                            <div class="invoice-body-value">{{ data.payment.for }}</div>
                                            <div class="invoice-body-title">Payment For</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="row">
                                <div class="col-6">تاريخ الوصل</div>
                                <div class="col-6 text-left">Date</div>
                                <div class="col-12 text-center">
                                    <span class="invoice-date">{{ data.payment.date }}</span>
                                    <br>
                                    <qr-code :text="data.qr_url" size="200"></qr-code>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="invoice-footer">
                        العنوان : {{ data.complex.address }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default ({
    data() {
        return {
            data: null,
        }
    },
    methods: {
        async print(id, message = false) {
            await this.$store.dispatch('printPayment', {
                id: id
            })
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
            window.print();
            if (message) {
                this.successMessage();
            }
        },
        successMessage() {
            // this.$swal({
            //     title: 'تمت العملية بنجاح',
            //     type: 'success',
            //     icon: 'success',
            //     showConfirmButton: false,
            //     timer: 1500,
            // });

        }
    }
})
</script>
