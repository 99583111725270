<template>
  <div class="home w-100">
    <!-- Page content -->

    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">
                <a
                  data-toggle="modal"
                  data-target="#addModal"
                  class="add-btn-options add-btn"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>

                <a
                  v-if="selectedItems && selectedItems.length > 0"
                  @click="confirmDelete()"
                  id="deleteButton"
                  class="card-title-options delete-btn"
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>

                <a
                  v-if="selectedItems && selectedItems.length == 1"
                  id="editModal"
                  class="card-title-options edit-btn"
                  @click="openEditModal()"
                  data-toggle="modal"
                  data-target="#modalEdit"
                >
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </a>

                <a
                  v-if="selectedItems && selectedItems.length == 1"
                  id="showButton"
                  data-toggle="modal"
                  data-target="#showModal"
                  class="card-title-options show-btn"
                  @click="openShowModal()"
                >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a>

                <router-link
                  :to="{
                    name: 'real-estate-images',
                    params: { id: selectedItems[0] },
                  }"
                  v-if="selectedItems && selectedItems.length == 1"
                  id="editModal"
                  class="card-title-options edit-btn"
                >
                  <i class="fa fa-image" aria-hidden="true"></i>
                </router-link>
              </div>

              <div class="right-title">
                <span class="nav-title">ادارة العقارات</span>
              </div>
            </div>
            <div class="form-group search-group">
              <label for="search">البحث</label>
              <div class="search-switch">
                <label class="switch">
                  <div>
                    <p class="status-text">الحالة</p>
                  </div>
                  <div class="switch-input">
                    <input type="checkbox" v-model="switchValue" />
                    <span class="slider round"></span>
                  </div>
                </label>
              </div>
              <input
                type="search"
                class="form-control red-input-border search-input"
                v-model="search"
                @input="handleSearch"
                placeholder="اسم او رقم ..."
              />
            </div>

            <loading-table-component v-if="loading" />

            <div class="table-responsive" v-else>
              <table class="table table-hover" id="selectableTable">
                <thead>
                  <tr>
                    <th scope="col">ت</th>
                    <th scope="col">اسم ورقم العقار</th>
                    <th scope="col" v-if="type == 'buildings'">الطابق</th>
                    <th scope="col">المالك</th>
                    <th scope="col">الخارطة</th>
                    <th scope="col">فئة العقار</th>
                    <th scope="col">واجهة العقار</th>
                    <th scope="col">الحالة</th>
                    <th scope="col" v-if="!switchValue">نقل الملكية</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in items"
                    :key="item.id"
                    :class="{ selected: isSelected(item.id) }"
                    @click="handleSelection(item.id)"
                  >
                    <td>{{ getIndex(index) }}</td>
                    <td>{{ item.name }}</td>
                    <td v-if="type == 'buildings'">{{ item.floor }}</td>
                    <td>
                      <a v-if="item.client">{{ item.client?.name }}</a>
                      <span v-else class="status-unavailable">لا يوجد</span>
                    </td>
                    <td>
                      <!-- add icon for download -->

                      <i
                        v-if="item.map"
                        @click="download(item.map)"
                        class="fa fa-download downloadicon"
                      ></i>
                      <span v-else class="status-unavailable">لا يوجد</span>
                    </td>
                    <td>
                      {{ item.category.name }}
                    </td>
                    <td>{{ item.front }}</td>
                    <td>
                      <span
                        v-if="item.status == 'available'"
                        class="status-available"
                        >متاح</span
                      >
                      <span
                        v-if="item.status == 'sold'"
                        class="status-unavailable"
                        >غير متاح</span
                      >
                    </td>
                    <td v-if="!switchValue">
                      <button
                        class="btn btn-primary"
                        data-target="#showTransfareModal"
                        v-if="item.client"
                        @click="openShowTransfareModal(item.client, item)"
                      >
                        <i class="fa fa-exchange"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="pagination-container" v-if="!loading">
                <pagination-component
                  :currentPage="currentPage"
                  :lastPage="lastPage"
                  @getData="getRealEstate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AddRealEstateComponent @getData="getRealEstate" />
    <EditRealEstateComponent
      ref="editRealEstateComponent"
      @getData="getRealEstate"
    />
    <TransfareRealEstateComponent
      ref="transfareRealEstateComponent"
      @getData="getRealEstate"
    />
    <ShowRealEstateComponent ref="showRealEstateComponent" />
  </div>
</template>

<script>
import AddRealEstateComponent from "@/components/RealEstate/AddRealEstateComponent.vue";
import EditRealEstateComponent from "@/components/RealEstate/EditRealEstateComponent.vue";
import ShowRealEstateComponent from "@/components/RealEstate/ShowRealEstateComponent.vue";
import TransfareRealEstateComponent from "@/components/RealEstate/TransfareRealEstateComponent.vue";
export default {
  components: {
    AddRealEstateComponent,
    EditRealEstateComponent,
    ShowRealEstateComponent,
    TransfareRealEstateComponent,
  },
  data() {
    return {
      switchValue: true,
      search: "",
      loading: false,
      debounceTimer: null,
      status: "available",
      type: "",
      items: [],
      selectedItems: [],
      edit_id: null,
      editModal: false,
      currentPage: 1,
      lastPage: 1,
    };
  },
  watch: {
    switchValue(newVal, oldVal) {
      this.currentPage = 1;
      if (newVal) {
        this.status = "available";
        this.getRealEstate(this.currentPage);
      } else {
        this.status = "sold";
        this.getRealEstate(this.currentPage);
      }
    },
  },
  mounted() {
    this.getRealEstate(this.currentPage);
  },
  methods: {
    getType() {
      this.type = localStorage.getItem("type");
    },
    openShowTransfareModal(client, real_estate) {
      $("#showTransfareModal").modal("show");
      console.log(client);
      this.$refs.transfareRealEstateComponent.getCurrentOwner(
        client,
        real_estate
      );
    },
    openEditModal() {
      let id = this.selectedItems[0];
      $("#modalEdit").modal("show");
      this.$refs.editRealEstateComponent.showRealEstate(id);
    },
    changeStatus() {
      this.switchValue = !this.switchValue;
    },
    getIndex(index) {
      return index + 1 + (this.currentPage - 1) * 10;
    },
    download(url) {
      window.open(url, "_blank").focus();
    },
    openShowModal() {
      let id = this.selectedItems[0];
      $("#showModal").modal("show");
      this.$refs.showRealEstateComponent.showRealEstate(id);
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getRealEstate(this.currentPage);
      }, 500);
    },
    getRealEstate(currentPage) {
      this.loading = true;
      this.$store
        .dispatch("getRealEstate", {
          page: currentPage,
          search: this.search,
          status: this.status,
        })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    lected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    handleSelection(itemId) {
      if (this.isSelected(itemId)) {
        this.selectedItems = this.selectedItems.filter((id) => id !== itemId);
      } else {
        this.selectedItems.push(itemId);
      }
    },
    async confirmDelete() {
      const result = await this.$swal.fire({
        title: "هل انت متأكد ؟",
        text: "لن تتمكن من التراجع عن هذا الاجراء!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم, احذفها!",
        cancelButtonText: "الغاء",
      });
      if (result.isConfirmed) {
        this.deleteRealEstate();
      }
    },
    async deleteRealEstate() {
      try {
        await this.$store.dispatch("deleteRealEstate", {
          ids: this.selectedItems,
        });
        this.selectedItems = [];
        await this.getRealEstate(this.currentPage);
        this.$swal.fire("تم الحذف", "تم الحذف بنجاح", "success");
      } catch (error) {
        await this.$swal.fire("خطآ", "حدثت مشكلة اثناء الحذف", "error");
      }
    },
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
  },
};
</script>
