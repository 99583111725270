<template>
  <div class="home w-100">

    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">

                <a data-toggle="modal" data-target="#addModal" class="add-btn-options add-btn">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length > 0" @click="confirmDeleteSale()" id="deleteButton"
                  class="card-title-options delete-btn">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>
<!-- 
                <a v-if="selectedItems && selectedItems.length == 1" id="editModal" class="card-title-options edit-btn"
                  data-toggle="modal" data-target="#modalEdit"
                  @click="openEditModal()"
                  >
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </a> -->

                <a v-if="selectedItems && selectedItems.length == 1" id="showButton" class="card-title-options show-btn"
                  data-toggle="modal" data-target="#showModal"
                  @click="openShowModal()"
                  >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a>

                <a @click="downloadExcel()" class="add-btn-options excel-btn">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </a>

              </div>

              <div class="right-title">
                <span class="nav-title">سجل المبيعات</span>
              </div>

            </div>

            <div class="form-group search-group">
              <label for="search">البحث</label>
              <div class="search-switch">
                <label class="switch">
                  <div>
                    <p class="status-text" v-text="status_text"></p>
                  </div>
                  <div class="switch-input">
                    <input type="checkbox" v-model="switchValue" />
                    <span class="slider round"></span>
                  </div>
                </label>
              </div>
              <input type="search" class="form-control red-input-border search-input" v-model="search"
                @input="handleSearch" placeholder="اسم او رقم ..." />

            </div>

            <loading-table-component v-if="loading" />
            <div class="table-responsive" v-else>

              <table class="table table-hover" id="selectableTable">
                <thead>
                  <tr>
                    <th scope="col">ت</th>
                    <th scope="col">اسم العميل</th>
                    <th scope="col">العقار</th>
                    <th scope="col">نوع التسديد</th>
                    <th scope="col">تاريخ العقد</th>
                    <th scope="col">رقم العقد</th>
                    <th scope="col">سعر البيع</th>
                    <th scope="col">المدفوع</th>
                    <th scope="col">المتبقي</th>
                    <th scope="col">تاريخ الاستحقاق</th>
                    <th scope="col" v-if="status == 'installment'">الدفعات</th>
                    <th scope="col">كشف الحساب</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id" :class="{ selected: isSelected(item.id) }"
                    @click="handleSelection(item.id)">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.client?.name }}</td>
                    <td>{{ item.real_estate.name }}</td>
                    <td>
                      <span v-if="item.payment_method == 'installment'" class="badge badge-secondary">
                        اقساط
                      </span>
                      <span v-else class="badge badge-primary">
                        نقدا
                      </span>
                    </td>
                    <td>{{ item.contract_date }}</td>
                    <td>
                      <span v-if="status == 'cash'">
                        {{ item.contract_number }}
                      </span>
                      <router-link v-else :to="{ name: 'payment_id', params: { id: item.id } }" class="watch-map">{{ item.contract_number }}</router-link>
                    </td>
                    <td>{{ item.selling_amount | currency }}</td>
                    <td>{{ item.paid_amount | currency }}</td>
                    <td>{{ item.remaining_amount | currency }}</td>
                    <td>{{ item.due_date }}</td>
                    <td v-if="status == 'installment'">
                      <router-link :to="{ name: 'payments_list', params: { id: item.id } }">
                      <i class="fa fa-paperclip"></i>
                      </router-link>
                    </td>
                    <td>
                      <router-link :to="{ name: 'report', params: { id: item.id } }">
                      <i class="fa fa-money"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="getSales" />
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
    <AddSaleComponent @getData="getSales" />
    <EditSaleComponent ref="editSaleComponent" @getData="getSales" />
    <ShowSaleComponent ref="showSaleComponent" />

  </div>
</template>


<script>
import AddSaleComponent from '@/components/Sales/AddSaleComponent.vue';
import EditSaleComponent from '@/components/Sales/EditSaleComponent.vue';
import ShowSaleComponent from '@/components/Sales/ShowSaleComponent.vue';

export default {
  components: {
    AddSaleComponent,
    EditSaleComponent,
    ShowSaleComponent,
  },
  data() {
    return {
      switchValue: true,
      status_text: 'اقساط',
      search: '',
      loading: false,
      debounceTimer: null,
      status: 'installment',
      items: [
      ],
      selectedItems: [],
      edit_id: null,
      editModal: false,
      currentPage: 1,
      lastPage: 1,
    };
  },
  watch: {
    switchValue(newVal, oldVal) {
      this.selectedItems = [];
      if (newVal) {
        this.status_text = 'اقساط';
        this.status = 'installment';
        this.getSales(this.currentPage);
      } else {
        this.status_text = 'نقدا';
        this.status = 'cash';
        this.getSales(this.currentPage);
      }
    },
  },

  mounted() {
    this.getSales(this.currentPage);
  },
  methods: {
    downloadExcel() {
      let base_url = this.$axios.defaults.baseURL;
      let token = localStorage.getItem('token');
      let exportUrl = `${base_url}/export/sales?type=${this.status}&token=${token}`;
      window.location.href = exportUrl;
    },
    openShowModal() {
      $('#showModal').modal('show');
      let id = this.selectedItems[0];
      this.$refs.showSaleComponent.showSale(id);
    },
    openEditModal() {
      let id = this.selectedItems[0];
      this.$refs.editSaleComponent.showSale(id);
      $('#modalEdit').modal('show');
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getSales(this.currentPage);
      }, 500);
    },
    getSales(currentPage) {
      this.loading = true;
      this.$store.dispatch('getSales', {
        page: currentPage,
        search: this.search,
        payment_method: this.status,
      })
        .then(response => {
          this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    handleSelection(itemId) {
      if (this.isSelected(itemId)) {
        this.selectedItems = this.selectedItems.filter((id) => id !== itemId);
      }
      else {
        this.selectedItems.push(itemId);
      }
    },
    async confirmDeleteSale() {
      const result = await this.$swal.fire({
        title: 'هل انت متأكد ؟',
        text: 'لن تتمكن من التراجع عن هذا الاجراء!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم, احذفها!',
        cancelButtonText: 'الغاء',
      });
      if (result.isConfirmed) {
        this.deleteSale();
      }
      else {
      }
    },
    async deleteSale() {
      try {
        await this.$store.dispatch('deleteSale', {
          ids: this.selectedItems,
        });
        this.selectedItems = [];
        await this.getSales(this.currentPage);
        this.$swal.fire('تم الحذف', 'تم الحذف بنجاح', 'success');
      }
      catch (error) {
        await this.$swal.fire('خطآ', 'حدثت مشكلة اثناء الحذف', 'error');
      }
    },
  },
};
</script>
