<template>
  <div class="login-view">
    <div class="body-login">
      <div class="container login-container">
        <div class="row login-row">
          <div class="col-sm-6 col-md-6 col-lg-6 padding-0">
            <div class="card left-card">
              <div class="card-body login-card-body">
                <form @submit.prevent="login">
                  <div class="form-group">
                    <label for="phone">رقم الهاتف</label>
                    <input
                      type="text"
                      class="form-control red-input-border"
                      id="phone"
                      v-model="form.phone"
                    />
                    <div v-if="errors.phone">
                      <p
                        class="input-error"
                        v-for="(error, index) in errors.phone"
                        :key="index"
                      >
                        {{ error }}
                      </p>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="password">كلمة المرور</label>
                    <input
                      type="password"
                      class="form-control red-input-border"
                      id="password"
                      v-model="form.password"
                    />
                    <div v-if="errors.password">
                      <p
                        class="input-error"
                        v-for="(error, index) in errors.password"
                        :key="index"
                      >
                        {{ error }}
                      </p>
                    </div>
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      v-model="form.remeber_me"
                    />
                    <label class="form-check-label" for="exampleCheck1"
                      >حفظ معلومات الدخول</label
                    >
                  </div>
                  <button
                    type="submit"
                    class="btn btn-secondary btn-primary-100 h-3"
                  >
                    <div
                      class="spinner-border"
                      role="status"
                      v-if="loading_spinner"
                    >
                      <span class="visually-hidden"></span>
                    </div>
                    تسجيل الدخول
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 padding-0">
            <div class="login-logo-side right-card">
              <img
                src="../assets/images/logo.png"
                alt="logo"
                class="img-fluid-2"
              />
              <span class="red-logo-title">نظام ادارة المجمعات السكنية</span>
              <div class="blur"></div>
            </div>
          </div>
        </div>
        <a class="red-logo-title_b">V 0.1 ,  جميع الحقوق محفوظة لشركة تطوير 2023 </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        phone: "",
        password: "",
        remeber_me: false,
      },
      loading_spinner: false,
      errors: [],
    };
  },
  methods: {
    async login() {
      if (this.loading_spinner) return;
      this.loading_spinner = true;
      await this.$axios
        .post("/api/auth/login", this.form)
        .then((response) => {
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("type", response.data.type);
          window.location.replace(response.data.path);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
      this.loading_spinner = false;
    },
  },
};
</script>
<style scoped>
img.img-fluid-2 {
  width: 10pc;
}
</style>
