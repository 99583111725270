<template>
  <div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="modalEditTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-side" role="document">
      <div class="modal-content modal-content-side">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEditTitle">
            <i class="fa fa-plus"></i>
            تعديل البيع
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-side">



          <div class="form-group">
            <label for="name">العميل</label>
            <v-select v-model="editForm.client_id" :options="clients" label="name" :dir="'rtl'" track-by="id"
              :searchable="true" @search="getClients" placeholder="ابحث عن العميل.." />
            <div v-if="errors.client_id">
              <p class="input-error" v-for="(error, index) in errors.client_id" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="name">العقار</label>
            <v-select v-model="editForm.real_estate_id" :options="real_estates" label="name" :dir="'rtl'" track-by="id"
              :searchable="true" @search="getRealEstates" placeholder="ابحث عن العقار.." />
            <div v-if="errors.real_estate_id">
              <p class="input-error" v-for="(error, index) in errors.real_estate_id" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="contract_date">تاريخ العقد</label>
            <input type="date" id="contract_date" class="form-control red-input-border" v-model="editForm.contract_date">
            <div v-if="errors.contract_date">
              <p class="input-error" v-for="(error, index) in errors.contract_date" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="contract_number">رقم العقد</label>
            <input type="number" id="contract_number" class="form-control red-input-border"
              v-model="editForm.contract_number">
            <div v-if="errors.contract_number">
              <p class="input-error" v-for="(error, index) in errors.contract_number" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="payment_method">نوع التسديد</label>
            <select id="payment_method" class="form-control red-input-border" v-model="editForm.payment_method" disabled>
              <option value="اقساط">اقساط</option>
              <option value="نقدا">نقدا</option>
            </select>
            <div v-if="errors.payment_method">
              <p class="input-error" v-for="(error, index) in errors.payment_method" :key="index">{{ error }}</p>
            </div>
          </div>


          <div class="form-group">
            <label for="selling_amount">سعر البيع</label>
            <input type="number" id="selling_amount" class="form-control red-input-border"
              v-model="editForm.selling_amount" @keyup="setMonthlyAmount()">
            <div v-if="errors.selling_amount">
              <p class="input-error" v-for="(error, index) in errors.selling_amount" :key="index">{{ error }}</p>
            </div>
          </div>


          <div v-if="editForm.payment_method == 'اقساط'">
            <div class="form-group">
              <label for="number_of_payments">عدد الدفعات</label>
              <input type="number" id="number_of_payments" class="form-control red-input-border"
                v-model="editForm.number_of_payments" @keyup="setMonthlyAmount()">
              <div v-if="errors.number_of_payments">
                <p class="input-error" v-for="(error, index) in errors.number_of_payments" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group">
              <label for="receipt_amount">المقدمة</label>
              <input type="number" id="receipt_amount" class="form-control red-input-border"
                v-model="editForm.receipt_amount" @keyup="setMonthlyAmount()">
              <div v-if="errors.receipt_amount">
                <p class="input-error" v-for="(error, index) in errors.receipt_amount" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group">
              <label for="monthly_amount">مبلغ الدفعات الشهرية</label>
              <input type="number" id="monthly_amount" class="form-control red-input-border"
                v-model="editForm.monthly_amount">
              <div v-if="errors.monthly_amount">
                <p class="input-error" v-for="(error, index) in errors.monthly_amount" :key="index">{{ error }}</p>
              </div>
            </div>
          </div>


          <hr>
          <button type="button" class="btn btn-primary  btn-primary-100" @click="editSale()">
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> حفظ

          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
      id: '',
      loading_spinner: false,
      clients: [],
      real_estates: [],
      errors: [],
      editForm: {
        payment_method: '',
        client_id: null,
        contract_number: '',
        real_estate_id: null,
        contract_date: '',
        number_of_payments: '',
        selling_amount: '',
        monthly_amount: '',
        receipt_amount: '',
      }
    }
  },
  methods: {
    showSale(id) {
      this.id = id;
      this.loading_spinner = false;
      this.$store.dispatch('showSale', id)
        .then(response => {
          this.editForm = response.data;
          this.editForm.client_id = {
            id: response.data.client.id,
            name: response.data.client?.name
          };
          this.editForm.real_estate_id = {
            id: response.data.real_estate.id,
            name: response.data.real_estate.name
          };
        })
        .catch(error => {
        });
    },
    async editSale() {
      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      this.editForm.client_id = this.editForm.client_id ? this.editForm.client_id.id : null;
      this.editForm.real_estate_id = this.editForm.real_estate_id ? this.editForm.real_estate_id.id : null;
      let data = {
        id: this.id,
        form: this.editForm
      }
      await this.$store.dispatch('editSale', data)
        .then(response => {
          this.$emit('editSale', response.data);
          $('#modalEdit').modal('hide');
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تمت التعديل بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
      this.real_estates = [];
      this.loading_spinner = false;
    },
    setMonthlyAmount() {
      if (this.editForm.number_of_payments && this.editForm.selling_amount && this.editForm.receipt_amount) {
        this.editForm.monthly_amount = (this.editForm.selling_amount - this.editForm.receipt_amount) / this.editForm.number_of_payments
      }
    },
    getClients(query) {
      this.loading = true;
      this.$store.dispatch('getClientsList', {
        search: query,
      })
        .then((response) => {
          this.clients = response.data.data;
        });
    },
    getRealEstates(query) {
      this.$store.dispatch('getRealEstate', {
        search: query,
        status: 'available'
      })
        .then(response => {
          this.real_estates = response.data.data;
        })
        .catch(error => {
        });
    }
  }
})
</script>
