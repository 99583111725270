<template>
  <div class="home w-100">

    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">


              </div>

              <div class="right-title">
                <span class="nav-title">سجل الدفعات</span>
              </div>

            </div>

            <div class="form-group search-group">
              <label for="search">البحث</label>
              <input type="search" class="form-control red-input-border search-input" v-model="search"
                @input="handleSearch" placeholder="بحث ..." />

            </div>

            <loading-table-component v-if="loading" />
            <div class="table-responsive" v-else>

              <table class="table table-hover" id="selectableTable">
                <thead>
                  <tr>
                    <th scope="col">ت</th>
                    <th scope="col">رقم الدفعة</th>
                    <th scope="col">المبلغ</th>
                    <th scope="col">المسدد</th>
                    <th scope="col">تاريخ الاستحقاق</th>
                    <th scope="col">طريقة التسديد</th>
                    <th scope="col">تاريخ التسديد</th>
                    <th scope="col">اختيارات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.payment_number }}</td>
                    <td>{{ item.payment_amount | currency }}</td>
                    <td>{{ item.client?.name }}</td>
                    <td>{{ item.payment_date }}</td>
                    <td>{{ item.payment_type == 'cash' ? 'نقدا' : 'حساب مصرفي' }}</td>
                    <td>{{ item.paid_at ? item.paid_at : 'غير مسددة' }}</td>
                    <td dir="ltr">
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button v-if="item.status == 'paid'" class="btn btn-primary" @click="print(item.id)">
                          <div class="spinner-border" role="status" v-if="loading_spinner[index]">
                            <span class="visually-hidden"></span>
                          </div>
                          <i class="fa fa-print" v-else></i>
                        </button>
                        <a :href="item.signed_voucher" download v-if="item.signed_voucher" class="btn btn-secondary">
                          <i class="fa fa-download"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="paymentBySale" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>






    <PrintInvoiceComponent ref="printInvoiceComponent" />




  </div>
</template>


<script>
import PrintInvoiceComponent from '@/components/Global/PrintInvoiceComponent.vue';
export default {
  components: {
    PrintInvoiceComponent,
  },
  data() {
    return {
      search: '',
      loading: false,
      selected_contract: null,
      items: [
      ],
      currentPage: 1,
      lastPage: 1,
      loading_spinner: [],
    };
  },
  mounted() {
    this.paymentBySale(this.currentPage);
  },
  methods: {
    async print(id) {
      this.$refs.printInvoiceComponent.print(id);
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.paymentBySale(this.currentPage);
      }, 500);
    },
    paymentBySale(currentPage) {
      this.loading = true;
      let params = {
        query: {
          page: currentPage,
          search: this.search,
          payment_method: this.status,
        },
        id: this.$route.params.id
      }
      this.$store.dispatch('paymentBySale', params)
        .then(response => {
          this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        })
        .catch(error => {
          this.loading = false;
        });
    },
  },
};
</script>
