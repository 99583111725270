<template>
  <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showModalLabel">عرض العميل</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body show-card-body">
          <!--  -->
          <loading-table-component v-if="loading" />

          <div class="row" v-else>
            <div v-if="info">
              <div class="col-6 show-title">
              <b>اسم العميل</b>
            </div>
            <div class="col-6 show-value">{{ info.client?.name }}</div>
            <div class="col-6 show-title">
              <b>رقم هاتف العميل</b>
            </div>
            <div class="col-6 show-value">
              <span class="badge badge-success" v-if="info.client.phone">{{ info.client.phone }}</span>
              <span class="badge badge-success" v-else>لا يوجد</span>
            </div>
            <!--  -->
            <div class="col-6 show-title">
              <b>رقم العقد</b>
            </div>
            <div class="col-6 show-value">
              {{ info.contract_number }}
            </div>
            <!--  -->
            <div class="col-6 show-title">
              <b>تاريخ العقد</b>
            </div>
            <div class="col-6 show-value">
              {{ info.contract_date }}
            </div>
            <!--  -->
            <div class="col-6 show-title">
              <b>سعر البيع</b>
            </div>
            <div class="col-6 show-value">
              {{ info.selling_amount | currency }}
            </div>
            <!--  -->
            <div class="col-6 show-title">
              <b>الخصم</b>
            </div>
            <div class="col-6 show-value">
              <span class="badge badge-success" v-if="info.percentage_discount">{{ info.percentage_discount }} %</span>
              <span class="badge badge-danger" v-else>لا يوجد</span>
            </div>
            <!--  -->
            <div class="col-6 show-title" v-if="info.percentage_discount">
              <b>سعر البيع بعد الخصم</b>
            </div>
            <div class="col-6 show-value" v-if="info.percentage_discount">
              {{ info.selling_amount_after_discount | currency }}
            </div>
            <!--  -->
            <div class="col-12" v-if="info.payment_method == 'installment'">
              <div class="row">
                <div class="col-6 show-title">
                  <b>المقدمة</b>
                </div>
                <div class="col-6 show-value">
                  {{ info.first_amount | currency }}
                </div>
                <div class="col-6 show-title">
                <b>الاقساط الشهرية</b>
              </div>
              <div class="col-6 show-value">
                {{ info.installment_amount | currency }}
              </div>
              <!--  -->
              <div class="col-6 show-title">
                <b>دفعة الاستلام</b>
              </div>
              <div class="col-6 show-value">
                {{ info.receipt_amount | currency }}
              </div>
              </div>
              <!--  -->
            </div>
            <!--  -->







            <div class="col-6 show-title">
              <b>المبلغ المدفوع</b>
            </div>
            <div class="col-6 show-value">
              {{ info.paid_amount | currency }}
            </div>
            <!--  -->

            <div class="col-6 show-title" v-if="info.payment_method == 'installment'">
              <b>المبلغ المتبقي</b>
            </div>
            <div class="col-6 show-value" v-if="info.payment_method == 'installment'">
              {{ info.remaining_amount | currency }}
            </div>
            <!--  -->
            <!--  -->

            <div class="col-12" v-if="info.payment_method == 'installment'">
              <div class="row">
                <div class="col-6 show-title">
                  <b>عدد الدفعات</b>
                </div>
                <div class="col-6 show-value">
                  {{ info.number_of_payments }}
                </div>
                <!--  -->
                <div class="col-6 show-title">
                  <b>الدفعات المسددة</b>
                </div>
                <div class="col-6 show-value">
                  {{ info.paid_payments_count }}
                </div>
                <!--  -->
                <div class="col-6 show-title">
                  <b>الدفعات الغير مسددة</b>
                </div>
                <div class="col-6 show-value">
                  {{ info.unpaid_payments_count }}
                </div>
                <!--  -->

              </div>
            </div>

            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">خروج</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
      info: '',
      loading: false,
    }
  },
  methods: {
    async showSale(id) {
      this.loading_spinner = false;
      this.loading = true;
      await this.$store.dispatch('showSale', id)
        .then(response => {
          this.info = response.data;
          console.log(this.info);
        })
        .catch(error => {
        });
        this.loading = false;
    },
  }
})
</script>
