<template>
  <div class="home">
    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">

    
              </div>

              <div class="right-title">
                <span class="nav-title">كشف الحساب</span>
              </div>

            </div>

            <div class="form-group search-group">
              <label for="search">البحث</label>
              <input type="search" class="form-control red-input-border search-input" v-model="search"
              @input="handleSearch" 
                placeholder="اسم او رقم ..." />

            </div>

            <loading-table-component v-if="loading" />

            <div class="table-responsive"  v-else>
            <table class="table table-hover" id="selectableTable">

              <thead>
                <tr>
                  <th scope="col">ت</th>
                  <th scope="col">اسم العميل</th>
                  <th scope="col">العقار</th>
                  <th scope="col">نوع التسديد</th>
                  <th scope="col">تاريخ العقد</th>
                  <th scope="col">تاريخ اخر دفعة</th>
                  <th scope="col">تاريخ الاستحقاق</th>
                  <th scope="col">مجموع المسدد</th>
                  <th scope="col">قيمة بيع الوحدة</th>
                  <th scope="col">كشف الحساب</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="item.id" 
                  @click="handleSelection(item.id)">
                  <td>{{ getIndex(index) }}</td>
                  <td>{{ item.client?.name }}</td>
                  <td>{{ item.real_estate.name }}</td>
                  <td>
                    <span v-if="item.payment_method == 'اقساط'" class="badge badge-secondary">
                        {{ item.payment_method }}
                      </span>
                      <span v-else class="badge badge-primary">
                        {{ item.payment_method }}
                      </span>
                  </td>
                  <td>{{ item.contract_date }}</td>
                  <td>{{ item.last_payment.payment_date }}</td>
                  <td>{{ item.due_date }}</td>
                  <td>{{ item.payments_sum_payment_amount | currency }}</td>
                  <td>{{ item.selling_amount | currency }}</td>
                  <td>
                    <router-link :to="{ name: 'report', params: {
                      id: item.id,
                    } }">
                      <i class="fa fa-paperclip"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="getSales" />
              </div>

          </div>

          </div>
        </div>


      </div>

    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      loading: false,
      search: '',
      items: [],
      currentPage: 1,
      lastPage: 1,
    };
  },
  mounted() {
    this.getSales();
  },
  methods: {
    getSales(currentPage) {
      this.loading = true;
      this.$store.dispatch('getSales', {
        page: currentPage,
        search: this.search,
        status: this.status,
      })
      .then((response) => {
        this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
      });
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getSales();
      }, 500);
    },
    getIndex(index) {
      return index + 1 + (this.currentPage - 1) * 10;
    },
  },
};
</script>
