<template>
  <div class="p-b-1 mt-3">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="left-options">
        <div class="dropdown">
          <a
            href="http://"
            class="nav-options"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-user-circle-o nav-icon" aria-hidden="true"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" @click="logout()">تسجيل الخروج</a>
            <a class="dropdown-item" @click="changeLogoModal()"
              >تبديل الشعار
            </a>
          </div>
        </div>
        <div class="modal fade" v-show="modalLogo" id="modalEdit2" tabindex="-1" role="dialog" aria-labelledby="modalEditTitle" aria-hidden="true">
          <input
          type="file"
          class="form-control red-input-border"
          name="logo"
          id="upload-logo"
          accept="image/*"
        />


        </div>

        <!-- <div class="dropdown">
          <a href="http://" class="nav-options" type="button" id="notifications" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-bell-o nav-icon" aria-hidden="true"></i>
        </a>
          <div class="dropdown-menu notifications-menu" aria-labelledby="notifications">
            <ul>
              <li class="notification-list">اليوم موعد تسديد الاقساط</li>
              <li class="notification-list">اليوم موعد تسديد الاقساطاليوم موعد تسديد الاقساط</li>
              <li class="notification-list">اليوم موعد تسديد الاقساط</li>
              <li class="notification-list">اليوم موعد تسديد الاقساط</li>
              <li class="notification-list">اليوم موعد تسديد الاقساط</li>
            </ul>
          </div>
        </div> -->
      </div>
      <div class="right-title">
        <span class="nav-title">نظام ادارة المجمعات السكنية</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalLogo: false,
    };
  },
  methods: {
    logout() {
      this.$emit("logout");
    },
    changeLogoModal() {
      // this.$emit("changeLogo");
      $('#modalEdit2').modal('show');
      this.modalLogo = true;
    },
  },
};
</script>
