<template>
  <div class="home w-100">

    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">
                <a @click="downloadExcel()" class="add-btn-options excel-btn">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </a>
              </div>

              <div class="right-title">
                <span class="nav-title">سجل الدفعات</span>
              </div>

            </div>

            <div class="form-group search-group">
              <label for="search">البحث</label>
              <div class="search-switch">
                <label class="switch">
                  <div>
                    <p class="status-text" v-text="status_text"></p>
                  </div>
                  <div class="switch-input">
                    <input type="checkbox" v-model="switchValue" />
                    <span class="slider round"></span>
                  </div>
                </label>
              </div>
              <input type="search" class="form-control red-input-border search-input" v-model="search"
                @input="handleSearch" placeholder="اسم او رقم ..." />

            </div>

            <loading-table-component v-if="loading" />
            <div class="table-responsive" v-else>

              <table class="table table-hover" id="selectableTable">
                <thead>
                  <tr>
                    <th scope="col">ت</th>
                    <th scope="col">اسم العميل</th>
                    <th scope="col">العقار</th>
                    <th scope="col">البناية</th>
                    <th scope="col">
                      <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>
                    </th>
                    <th scope="col">رقم الدفعة</th>
                    <th scope="col">مبلغ الدفعة</th>
                    <th scope="col">طريقة التسديد</th>
                    <th scope="col">تاريخ الدفعة</th>
                    <th v-if="status == 'unpaid'" scope="col">تسديد</th>
                    <th scope="col">اختيارات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.client?.name }}</td>
                    <td>{{ item.sale?.real_estate?.name }}</td>
                    <td>{{ item.sale?.real_estate?.building?.name }}</td>
                    <td>{{ item.sale?.real_estate?.building?.complex?.name }}</td>
                    <td>{{ item.payment_number }}</td>
                    <td>{{ item.payment_amount | currency }}</td>
                    <td>{{ item.payment_type == 'cash' ? 'نقدا' : 'حساب مصرفي' }}</td>
                    <td>{{ item.payment_date }}</td>
                    <td v-if="status == 'unpaid'">
                      <router-link :to="{ name: 'payment_id', params: { id: item.sale_id } }" class="watch-map">
                        <i class="fa fa-money"></i>
                      </router-link>
                    </td>
                    <td dir="ltr">
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button v-if="item.status == 'paid'" class="btn btn-primary" @click="print(item.id)">
                        <i class="fa fa-print"></i>
                      </button>
                      <button class="btn btn-danger ml-1" @click="trash(item.id)">
                        <i class="fa fa-trash"></i>
                      </button>
                        <a :href="item.signed_voucher" download v-if="item.signed_voucher" class="btn btn-secondary">
                          <i class="fa fa-download"></i>
                        </a>
                      </div>

                    </td>
                    
                  </tr>
                </tbody>
              </table>
              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="getPayments" />
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>


    <PrintInvoiceComponent ref="printInvoiceComponent" />


  </div>
</template>


<script>
import PrintInvoiceComponent from '@/components/Global/PrintInvoiceComponent.vue';
export default {
  components: {
        PrintInvoiceComponent,
    },
  data() {
    return {
      selected_contract: null,
      switchValue: true,
      status_text: 'مسددة',
      search: '',
      loading: false,
      debounceTimer: null,
      status: 'paid',
      items: [
      ],
      selectedItems: [],
      edit_id: null,
      editModal: false,
      currentPage: 1,
      lastPage: 1,
    };
  },
  watch: {
    switchValue(newVal, oldVal) {
      if (newVal) {
        this.status_text = 'مسددة';
        this.status = 'paid';
        this.getPayments(this.currentPage);
      } else {
        this.status_text = 'غير مسددة';
        this.status = 'unpaid';
        this.getPayments(this.currentPage);
      }
    },
  },

  mounted() {
    this.getPayments(this.currentPage);
  },
  methods: {
    downloadExcel() {
      let base_url = this.$axios.defaults.baseURL;
      let token = localStorage.getItem('token');
      let exportUrl = `${base_url}/export/payments?token=${token}`;
      window.location.href = exportUrl;
    },
    async print(id) {
      this.$refs.printInvoiceComponent.print(id);
    },
    async trash(id) {
      this.$swal({
        title: 'هل انت متأكد؟',
        text: "لن تتمكن من استعادة هذه البيانات!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم, احذفها!',
        cancelButtonText: 'الغاء'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('deletePayment', id)
            .then(response => {
              this.getPayments(this.currentPage);
              this.$swal(
                'تم الحذف!',
                'تم حذف البيانات بنجاح.',
                'success'
              );
            })
            .catch(error => {
              this.$swal(
                'خطأ!',
                'حدث خطأ اثناء الحذف.',
                'error'
              );
            });
        }
      });
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getPayments(this.currentPage);
      }, 500);
    },
    getPayments(currentPage) {
      this.loading = true;
      this.$store.dispatch('getPayments', {
        page: currentPage,
        search: this.search,
        status: this.status,
      })
        .then(response => {
          this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        })
        .catch(error => {
          this.loading = false;
        });
    },


  },
};
</script>

<style scoped>
.status-text {
  margin-right: -5pc;
  padding: 3px;
}
</style>